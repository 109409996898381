
import Vue from "vue";

import { authMapActions, authMapMutations } from "@/store/modules/auth";
import { validateFields } from "@/helpers";
import * as types from "@/store/mutation-types";
import { logoPath } from "@/helpers/generalHelpers";

interface ILoginData {
  loading: boolean;
  error: string;
  mfaOptions: any[];
  mfaMethod: "email" | "phonePrimary" | "phoneSecondary";
}

export default Vue.extend({
  name: "LoginAuthenticate",
  data(): ILoginData {
    return {
      loading: false,
      error: "",
      mfaOptions: [],
      mfaMethod: "email"
    };
  },
  mounted() {
    const { mfaOptions } = this.$route.meta || {};
    for (const option of Object.keys(mfaOptions)) {
      if (mfaOptions[option]) {
        this.mfaOptions.push({
          label: `${option === "email" ? "Email" : "Text"} ${
            mfaOptions[option]
          }`,
          value: option
        });
      }
    }
  },
  methods: {
    ...authMapActions(["requestMfaMethod"]),
    ...authMapMutations([types.LOGOUT]),
    getLogoPath() {
      return logoPath();
    },
    async mfaRequest(): Promise<void> {
      this.error = "";
      this.loading = true;
      const loading = this.$loading({
        lock: true
      });
      try {
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (isValid) {
          const { mfaMethod } = this;
          await this.requestMfaMethod(mfaMethod);
          const mfaAccount = this?.$route?.meta?.mfaOptions[mfaMethod];
          this.$router
            .replace({
              name: "mfa-verify",
              params: {
                mfaAccount,
                mfaMethod
              }
            })
            .catch(() => {});
        }
      } catch (e) {
        this.error = e.message;
        this.$appNotifyError(this.error);
        this.$bugSnagClient.notify(e);
      } finally {
        loading.close();
        this.loading = false;
      }
    }
  },
  beforeDestroy() {
    localStorage.removeItem("auth_mfa_request");
    this.LOGOUT(false);
  }
});
